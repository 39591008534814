import React, { PureComponent} from "react";
import InputFieldIcon from '../common/InputFieldIcon';
import WrapComponent from './WrapComponent';

class StepTwo extends PureComponent {

  constructor(props) {
    super(props);
    const {data} = props;
    this.state = {
      bottles: data.bottles,
      electronics: '',
      smallAppliances: '',
    };
  }

  clickOnContinue = () => {
    const { handleStep } = this.props;
    handleStep(3, this.state)
  }

  checkFillCompleted = () => {
    const { bottles, electronics, smallAppliances } = this.state;
    // return (Number(bottles) > 0 && Number(electronics) > 0 && Number(smallAppliances) > 0);
    return Number(bottles) > 0;
  }

  handleChangeBottle = (bottles) => {
    if (Number(bottles) > 999)
      return;
    this.setState({bottles})
  }

  render () {
    const { bottles, electronics, smallAppliances } = this.state;
    const fillCompleted = this.checkFillCompleted();
    const activeContinue = fillCompleted ? 'active' : 'disabled';

    return (
      <WrapComponent className="wrap-step-two" step='2'
        kind_of='Recyclables Quantity'
        fillCompleted={fillCompleted}
        handleNextStep={this.clickOnContinue}>

        <InputFieldIcon value={bottles}
          name="bottles"
          unit="Bags"
          onChange={(bottles) => this.handleChangeBottle(bottles)}
          label="Bottles & Cans"
          placeholder="0"
          type="number"
          note="Must have a minimum of <span class='color-green'>150 bottles (cans)</span> for a pickup"
        />


        <button className={`btn btn-continue ${activeContinue}`} type="button" onClick={this.clickOnContinue}>
          Continue
        </button>

        {
          this.props.errorMessage &&
          <div className='error-message'>{this.props.errorMessage}</div>
        }
      </WrapComponent>
    )
  }
}
export default StepTwo;
