import React, { PureComponent} from "react";
import WrapComponent from './WrapComponent';

class ContactAdmin extends PureComponent {

  clickOnMe(){
    $('#create_order').modal('hide');
  }

  render () {
    return (
      <WrapComponent className="wrap-contact-admin">
        <div className='text-center'>
          <div className='mb-3 mt-2'>Your request cannot be completed. Only one pickup order can be created at a time.</div>
          <div>Contact support@dzip.ca for more information.</div>
        </div>
        <button className={`btn btn-continue active`} type="button" onClick={this.clickOnMe}>
          OK
        </button>
      </WrapComponent>
    );
  }
}

export default ContactAdmin;
