import React, {Fragment, PureComponent} from "react";
import WrapComponent from './WrapComponent';

const TypeInfo = [
  {
    id: 0,
    slug: 'no_sort',
    name: "No Sort",
    description: "Curabitur lobortis id lorem id bibendum. Ut id consectetur magna.",
  },
  {
    id: 1,
    slug: 'full_sort',
    name: "Full Sort",
    description: "Curabitur lobortis id lorem id bibendum. Ut id consectetur magna.",
  }
]

class ListType extends PureComponent {

  constructor(props) {
    super(props);
    const { eventType } = props;
    const typeIDSelected =  eventType ? eventType['id'] : null;
    this.state = {
      selected: typeIDSelected
    };
  }

  selectEventType = (typeId) => {
    this.setState({selected: typeId})
  }

  clickOnContinue = () => {
    const { handleStep } = this.props;
    handleStep(1, TypeInfo[this.state.selected]);
  }

  renderCateItem = cate => {
    const { selected } = this.state;
    const klazzActive = selected === cate['id'] ? 'active' : '';
    return(
      <div className={`cate-item ${klazzActive}`} key={cate['id']} data="earn_cash"  onClick={()=>this.selectEventType(cate['id'])}>
        <div className="bound-info">
          <h4 className="name-cate">{cate['name']}</h4>
          <div className="description">
            {cate['description']}
          </div>
        </div>
        <div className="bound-icon">
          <div className={`icon-catagory ${cate['slug']}`} />
        </div>
      </div>
    )
  }
  render () {
    const { selected } = this.state;
    const klazzActiveBtn = selected !== null ? 'active' : 'disabled';
    return (
      <WrapComponent className="wrap-list-category">
        {
          TypeInfo.map(cate => {
            return this.renderCateItem(cate)
          })
        }
        <button className={`btn btn-continue step-1 ${klazzActiveBtn}`} type='button' onClick={this.clickOnContinue}>
          Continue
        </button>
      </WrapComponent>
    );
  }
}

export default ListType;
