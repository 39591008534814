import React, {Fragment} from "react";
import {DATE_FORMAT} from './helpers/AppConstant';
import ListCategory from './create_orders/ListCategory';
import StepOne from './create_orders/StepOne';
import StepTwo from './create_orders/StepTwo';
import StepThree from './create_orders/StepThree';
import StepDone from './create_orders/StepDone';
import ContactAdmin from './create_orders/ContactAdmin';
import { CreateOrderContext } from '../services/CreateOrderContext';

import {_POST, _PUT} from './helpers/HttpRequest';

class CreateOrderContainer extends React.Component {
  constructor(props) {
    super(props);
    let customerAddress = Cookies.get('customer_address');
    if (customerAddress == undefined)
      customerAddress = '';

    this.state = {
      step: 0,
      category: null,
      errorMessage: '',
    }
    this.data = {
      category: '',
      fundraiser_id: '',
      address: customerAddress,
      preferred_pickup_date: '',
      frequency: '',
      bottles: '',
      electronics: '',
      small_appliances: '',
      comment: '',
      availabel_dates: [],
      id: null
    }
    this.VOOrder = null;
  }

  handleSubmitOrder = (data, callback) => {
    if (this.VOOrder !== null)
      _PUT(`/users/orders/${this.VOOrder.id}/jupdate`, {params: {order: data}}, callback);
    else
      _POST('/users/orders', {params: {order: data}}, callback);
  }

  handleCommentOrder = (id, data, callback) => {
    _PUT(`/users/orders/${id}/jsubmit`, {params : {order: data}}, callback);
  }

  handleStep = (nextStep, data) => {
    if (nextStep === 1){
      this.setState({step: nextStep, category: data})
      this.data['category'] = data['slug'];
    }
    else if (nextStep === 2){
      this.setState({step: nextStep})
      this.data['address'] = data.pickupAddress;
      this.data['preferred_pickup_date'] = data.pickupDate;
      this.data['frequency'] = data.frequency;
      this.data['fundraiser_id'] = data.fundraiserId;
      this.data['availabel_dates'] = data.availabelDates;
    }
    else if (nextStep === 3){
      this.setState({step: nextStep})
      this.data['bottles'] = data.bottles;
      this.data['electronics'] = data.electronics;
      this.data['small_appliances'] = data.smallAppliances;
    }
    else if (nextStep === 'submit'){
      if (data.couponApplied != null)
        this.data['coupon_id'] = data.couponApplied.id;
      else
        this.data['coupon_id'] = null;

      this.data['comment'] = data.comment;

      this.handleSubmitOrder(this.data, (result, error_code) => {
        if(error_code == 0){
          this.VOOrder = result;
          this.setState({step: 'done', errorMessage: ''});
          setTimeout(function(){ window.location.href = '/users/orders'; }, 3000);
        }
        else
          this.setState({errorMessage: "There're something wrong. Please try again."})
      })
    }
  }

  goBack = (step) => {
    this.setState({step: step, errorMessage: ''});
  }

  renderStep = () => {
    const { step, category, errorMessage } = this.state;
    const { frequencyCollection, fundraiserCollection, customerID } = this.props;

    switch (step){
      case 0: return <ListCategory category={category} handleStep={this.handleStep} />
      case 1: return <StepOne data={this.data} handleStep={this.handleStep} frequencyCollection={frequencyCollection} fundraiserCollection={fundraiserCollection} />
      case 2: return <StepTwo data={this.data} handleStep={this.handleStep} errorMessage={errorMessage} />
      case 3: return <StepThree data={this.data} customerID={customerID} handleStep={this.handleStep} errorMessage={errorMessage} />
      default: return <StepDone />
    }

  }

  render () {
    const { canCreatePickup} = this.props;
    const { category } = this.state;
    const valueProvider = {goBack: this.goBack, category: category }
    return (
      <Fragment>
        <CreateOrderContext.Provider value={valueProvider}>
          {
            canCreatePickup ? this.renderStep() : <ContactAdmin />
          }
        </CreateOrderContext.Provider>
      </Fragment>
    )


  }
}

export default CreateOrderContainer
