import React, {Fragment} from "react";

const DepotInfo = (props) => {
  const { depot } = props;
  return (
    <div className="popup-depot-info default-border-radius">
      {
        depot.bottle_hours && (
          <div className="item">
            <span className="label">Bottle Depot Hours (Secondary depot): </span>
            <span className="value">{depot.bottle_hours}</span>
          </div>
        )
      }
      
      <div className="item">
        <span className="label">Full Depot Open: </span>
        <span className="value">{depot.full_open}</span>
      </div>

      <div className="item">
        <span className="label">Stat Holidays: </span>
        <span className="value">{depot.stat_holidays}</span>
      </div>

      <div className="item">
        <span className="label">Closed: </span>
        <span className="value">{depot.closed}</span>
      </div>

      <div className="item">
        <span className="label">Address: </span>
        <span className="value">{depot.address}</span>
      </div>

      <div className="item">
        <span className="label">Phone: </span>
        <span className="value">{depot.phone}</span>
      </div>

      <div className="item">
        <span className="label">Email: </span>
        <span className="value">{depot.email}</span>
      </div>
    </div>
  )
}

export default DepotInfo;