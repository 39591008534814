import React, {Fragment, PureComponent} from "react";
import Util from './Util';

class InputFieldIcon extends PureComponent {

  constructor(props) {
    super(props);
  }

  handleChange = (event) => {
    this.props.onChange(event.target.value);
  }

  render () {
    const { value='', name = Util.randomString(), label,
            placeholder = '',
            type = 'text',
            note = null,
            unit = null,
            onChange } = this.props;
    return (
      <div className={`input-field icon ${name}`}>
        <label htmlFor={`field_${name}`}>{label}</label>
        <div className={`bound-input icon-${name}`}>
          <input
            value={value}
            placeholder={`${placeholder}`}
            type={`${type}`} name={`${name}`}
            id={`field_${name}`}
            onChange={this.handleChange}
          />
          { unit && <span className="unit">{unit}</span> }
        </div>
        {
          note && <div className="note-field" dangerouslySetInnerHTML={{__html: note}}></div>
        }
      </div>
    )
  }
}
export default InputFieldIcon;
