import React, {Fragment, PureComponent} from "react";
import Util from './Util';

class TextAreaField extends PureComponent {

  constructor(props) {
    super(props);
  }

  handleChange = (event) => {
    this.props.onChange(event.target.value);
  }

  render () {
    const { value='', name = Util.randomString(), label, placeholder = '', type = 'text', onChange } = this.props;
    return (
      <div className="input-field">
        <label htmlFor={`field_${name}`}>{label}</label>
        <textarea value={value}
          placeholder={`${placeholder}`}
          type={`${type}`} name={`${name}`}
          id={`field_${name}`}
          onChange={this.handleChange} />
      </div>
    )
  }
}
export default TextAreaField;