import React, {Fragment, PureComponent} from "react";
import WrapComponent from './WrapComponent';

const CategoryInfo = [
  {
    id: 0,
    slug: 'cash',
    name: "Earn money",
    description: "Earn money from the comfort of your home. We'll send an alert through our App and send your refund via e-transfer.",
  },
  {
    id: 1,
    slug: 'charity',
    name: "Donate to Fundraiser",
    description: "We can donate your refunds to help support your cause. Help our community!  ",
  }
]

class ListCategory extends PureComponent {

  constructor(props) {
    super(props);
    const { category } = props;
    const cateIDSelected =  category ? category['id'] : null;
    this.state = {
      selected: cateIDSelected
    };
  }

  selectCategory = (cateId) => {
    this.setState({selected: cateId})
  }

  clickOnContinue = () => {
    const { handleStep } = this.props;
    handleStep(1, CategoryInfo[this.state.selected]);
  }

  renderCateItem = cate => {
    const { selected } = this.state;
    const klazzActive = selected === cate['id'] ? 'active' : '';
    return(
      <div className={`cate-item ${klazzActive} ${cate.slug}`} key={cate['id']}   onClick={()=>this.selectCategory(cate['id'])}>
        <div className="bound-info">
          <h4 className="name-cate">{cate['name']}</h4>
          <div className="description">
            {cate['description']}
          </div>
        </div>
        <div className="bound-icon">
          <div className={`icon-catagory ${cate['slug']}`} />
        </div>
        <span className="material-icons check blue">check_circle</span>
      </div>
    )
  }
  render () {
    const { selected } = this.state;
    const klazzActiveBtn = selected !== null ? 'active' : 'disabled';
    return (
      <WrapComponent className="wrap-list-category">
        {
          CategoryInfo.map(cate => {
            return this.renderCateItem(cate)
          })
        }
        <button className={`btn btn-continue step-1 ${klazzActiveBtn}`} type="button" onClick={this.clickOnContinue}>
          Continue
        </button>
      </WrapComponent>
    );
  }
}

export default ListCategory;
