import React, { PureComponent} from "react";
import TextAreaField from '../common/TextAreaField';
import {_GET} from '../helpers/HttpRequest';
import InputField from '../common/InputField';
import WrapComponent from './WrapComponent';

class StepThree extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      comment: this.props.data.comment,
      couponCode: 'FREE SERVICE',
      couponApplied: null,
      couponMessage: null,
    };
  }

  clickOnContinue = () => {
    const { handleStep } = this.props;
    handleStep('submit', this.state);
  }

  checkFillCompleted = () => {
    const { comment } = this.state;
    return !(comment === '');
  }

  clickOnApplyCoupon = () => {
    if (this.state.couponCode.trim() == '') return;

    _GET(`/users/coupons/jfind?code=${this.state.couponCode}`, {}, (result, error_code) => {
      console.log('result', result);
      if (error_code == 0)
        this.setState({couponApplied: result})
      else
        this.setState({couponMessage: result, couponApplied: null})
    });
  }

  removeCoupon = () => {
    this.setState({couponMessage: null, couponApplied: null, couponCode: ''})
  }


  handleChangeCoupon = (couponCode) => {
    this.setState({couponCode: couponCode})
  }

  render () {
    const { customerID } = this.props;
    const { comment, couponCode, couponApplied, couponMessage } = this.state;
    const fillCompleted = this.checkFillCompleted();
    const activeContinue = fillCompleted ? 'active' : 'disabled';

    return (
      <WrapComponent className="wrap-step-three" step='3'
        kind_of='Bag Location'
        fillCompleted={fillCompleted}
        handleNextStep={this.clickOnContinue}>
        <div className="note mb-2">We recommend that you place your bags in a safe and discreet location.</div>
        <TextAreaField
          value={comment}
          onChange={(comment) => this.setState({comment})}
          label="Comment"
          placeholder="Tell us where your bag is located" />

        <div className="note bold">Your ID is <span className="order-id">{customerID}</span>. Please write this on your bag(s) if you can </div>


        <button className={`btn btn-continue ${activeContinue}`} type="button" onClick={this.clickOnContinue}>
          Submit Pickup Request
        </button>
        {
          this.props.errorMessage &&
          <div className='error-message'>{this.props.errorMessage}</div>
        }
      </WrapComponent>
    )
  }
}
export default StepThree;
