import React, {Fragment, PureComponent} from "react";
import {DATE_FORMAT} from '../helpers/AppConstant';
import InputAutoComplete from '../common/InputAutoComplete';
import InputDatePicker from '../common/InputDatePicker';
import SelectPicker from '../common/SelectPicker';
import DatePicker from '../common/DatePicker';
import moment from 'moment';
import WrapComponent from './WrapComponent';
import Select from 'react-select';

class StepOne extends PureComponent {

  constructor(props) {
    super(props);
    const {data} = props;
    this.state = {
      pickupAddress: data.address,
      pickupDate: data.preferred_pickup_date,
      frequency: data.frequency,
    };
  }

  clickOnContinue = () => {
    const { handleStep } = this.props;
    handleStep(2, this.state)
  }

  handleChangeAddress = (pickupAddress) => {
    this.setState({pickupAddress})
  }

  handleFrequencyChange = frequency => {
    this.setState({ frequency });
  }

  handlePickupDateChange = (date) => {
    this.setState({pickupDate: date})
  }

  checkFillCompleted = () => {
    const { pickupAddress, frequency, pickupDate } = this.state;
    // || frequency === ''
    return !(pickupAddress === '' || pickupDate === '');
  }

  render () {
    const { pickupAddress, frequency, pickupDate } = this.state;
    const { frequencyCollection  } = this.props;
    const fillCompleted = this.checkFillCompleted();
    const activeContinue = fillCompleted ? 'active' : 'disabled';
    return (
      <WrapComponent className="wrap-step-one" step='1'
        fillCompleted={fillCompleted}
        handleNextStep={this.clickOnContinue}>

        <InputAutoComplete
          validateBC={true}
          name="pickup_address"
          value={pickupAddress}
          onChange={this.handleChangeAddress}
          placeholder='123 Apple Avenue'
          label="Pickup Address"/>

        <InputDatePicker
          name="pickup_date"
          onChange={this.handlePickupDateChange}
          label="Requested Pickup Date"
          daysInAdv={2}
          value={pickupDate}
        />

        {/*<div className="input-field">
          <label>Preferred Frequency</label>
          <SelectPicker
            name="frequency"
            placeholder="How often?"
            includeBlank={false}
            value={frequency}
            onChange={this.handleFrequencyChange}
            options={frequencyCollection} />
        </div>*/}

        <button className={`btn btn-continue step-1 ${activeContinue}`} type="button" onClick={this.clickOnContinue}>
          Continue
        </button>
      </WrapComponent>
    )
  }
}
export default StepOne;
