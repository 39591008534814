import axios from 'axios';
const SUBMITING = {};

const HttpRequest = (
  method,
  endpoint,
  opts = { headers: {}, params: null },
  callback
) => {
  
  const keySubmit = CryptoJS.MD5(method + endpoint).toString();
  if (SUBMITING[keySubmit]) {
    return false;
  }

  const config = {
    url: endpoint,
    headers: Object.assign(
      {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      opts.headers,
    ),
    method,
    timeout: 15000,
    data: opts.params,
  };
  
  SUBMITING[keySubmit] = true;
  return axios
    .request(config)
    .then((response) => {
      delete SUBMITING[keySubmit];
      const { data: { error_code, data, message } } = response;
      if (error_code == 0)
        callback(data, error_code);
      else
        callback(message, error_code);
    })
    .catch((error) => {
      delete SUBMITING[keySubmit];
      const { response, request } = error;
      console.log('Rquest failure', request);
    });
};

export const _GET = (endpoint, opts, callback) => HttpRequest('GET', endpoint, opts, callback);

export const _POST = (endpoint, opts, callback) => HttpRequest('POST', endpoint, opts, callback);

export const _PUT = (endpoint, opts, callback) => HttpRequest('PUT', endpoint, opts, callback);

export const _DELETE = (endpoint, opts) => HttpRequest('DELETE', endpoint, opts, callback);
