import React, {Fragment, PureComponent} from "react";
import Util from './Util';

class SelectPicker extends PureComponent {

  constructor(props) {
    super(props);
    this.datePicker = null;
    this.fileId = `field_${this.props.name}`
  }

  componentDidMount = () => {
    $(`#${this.fileId}:not(jr-select)`).addClass('jr-select').selectpicker({ hideDisabled: true });
  }

  handleChange = (e) => {
    this.props.onChange(e.currentTarget.value);
  }

  render () {
    const { value = '', options, name, placeholder= 'Select item', includeBlank = true } = this.props;

    const listItems = options.map((item, index) =>
                        <option key={index} value={item[1]}>{item[0]}</option>
                      );
    return (
      <select id={this.fileId} onChange={this.handleChange} title={placeholder} defaultValue={value}>
        { includeBlank && <option value=''>{placeholder}</option>}
        { listItems }
      </select>
    )
  }
}
export default SelectPicker;