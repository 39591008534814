import React, { Fragment, PureComponent } from "react";
import axios from 'axios';
import InputAutoComplete from './common/InputAutoComplete';
import DepotInfo from './start_drop_off/DepotInfo';
import {_GET} from './helpers/HttpRequest';
// import recyclingImg from 'images/users/start-drop/recycling.png';

class StartDropOffContainer extends PureComponent {

  constructor(props){
    super(props);
    this.state = { currentAddress: '', numDepot: 0, closestDepot: null };
    this.markers = [];
    this.map = null;
    this.depots = [];
  }

  componentDidMount = () => {
    const center = {lat: 54.400182, lng: -124.906242};
    this.map = new google.maps.Map(
                      document.getElementById('nearest_depot_map'),
                      {zoom: 6, center: center, disableDefaultUI: true});

  
    this.getDepots((depots) => {
      this.depots = depots;
      const numDepot = depots.length;
      for(let i = 0; i < numDepot; i++){
        let latlng = new google.maps.LatLng(depots[i].address_lat, depots[i].address_lng);
        let marker = new google.maps.Marker({
                        position: latlng,
                        title: depots[i].address,
                        map: this.map,
                        customInfo: {depotIdx: i}
                      }); 
        this.markers.push(marker); 
      }
      this.setState({numDepot});
    })
  }

  getDepots = (callback) => {
    _GET('/users/recycling_depots.json', {}, callback);
  }

  findClosest = (location) => {
    const numOfMarker = this.markers.length;
    const closest = [];
    for (let i = 0; i < numOfMarker; i++) {
      this.markers[i].distance = google.maps.geometry.spherical.computeDistanceBetween(location, this.markers[i].getPosition());
      closest.push(this.markers[i]);
    }
    closest.sort(this.sortByDist);
    return closest[0];
  }

  sortByDist = (a, b) => {
    return (a.distance - b.distance)
  }

  handleChangeAddress = (currentAddress, location = null) => {
    if (location != null){
      const marker = this.findClosest(location);
      this.map.setCenter(marker.getPosition());
      this.map.setZoom(12);
      this.setState({currentAddress, closestDepot: this.depots[marker.customInfo.depotIdx]});
    }
    else this.setState({currentAddress});
  }

  renderInfoDepot = (closestDepot) => {

    if (closestDepot != null)
      return(
        <Fragment>
          <div className="depot-name">{closestDepot.name}</div>
          <div className="ml-auto info">
            <DepotInfo depot={closestDepot}/>
          </div>
        </Fragment>
      )
    else
      return(
        <Fragment>
          <div className="depot-name">{`${this.state.numDepot} Recycling Depot`}</div>
        </Fragment>
      )
  }

  render () {
    const { currentAddress, numDepot, closestDepot } = this.state;
    return (
      <section className="react-container start-drop-off">
        <div className="row">
          <div className="col-sm-12 header-text-block">
            <h3 className="header-text">Start a Drop-off</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <InputAutoComplete
              name="current_address"
              value={currentAddress}
              onChange={this.handleChangeAddress}
              placeholder='123 Apple Avenue'
              label="Current Address"
              disabled={numDepot == 0}
              geocoder={true}
            />

            <div className="bound-nearest-depot">
              <div className="subtitle">Our nearest depot</div>
              <div className="nearest-depot d-flex align-items-center">
                {this.renderInfoDepot(closestDepot)}
              </div>
              <div className="map" id="nearest_depot_map"></div>
            </div>

            {
              closestDepot &&
              <div className="controls d-flex">
                <a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${closestDepot.name},${closestDepot.address}`} className="btn no-border responsive">View on Map</a>
                <a target='_blank' href={`https://www.google.com/maps/dir/?api=1&origin=${currentAddress}&destination=${closestDepot.name},${closestDepot.address}`} className="btn primary-btn responsive">Get Direction</a>
              </div>
            }
          </div>
        </div>
      </section>
    );
  }
}

export default StartDropOffContainer
