import React, { PureComponent } from 'react';
import StepStatus from '../create_fundraisers/_StepStatus';

class WrapComponent extends PureComponent{
  render = () => {
    const { className = '', children,
            step = 0, kind_of = null,
            fillCompleted, handleNextStep
          } = this.props;
    return (
      <section className={`react-container ${className}`}>
        <div className="row">
          <div className="col-sm-12 header-text-block"><h3 className="header-text">Start a Fundraiser</h3></div>
        </div>

        {
          step > 0 && <StepStatus step={step} fillCompleted={fillCompleted} handleNextStep={handleNextStep} activeNextStep={fillCompleted} />
        }
        {
          kind_of && <div className='kind_of'>{kind_of}</div>
        }

        {children}
      </section>
    )
  }
}
export default WrapComponent;
