import React, {Fragment, PureComponent} from "react";
import { CreateOrderContext } from '../../services/CreateOrderContext';

class StepStatus extends PureComponent {
  static contextType = CreateOrderContext;
  goBack = null;
  category = null;

  componentWillMount() {
    this.goBack = this.context.goBack;
    this.category = this.context.category;
  }

  handleGoBack = (step, canBack) => {
    if(canBack)
      this.goBack(step);
  }

  handlePrevStep = () => {
    const { step } = this.props;
    this.goBack(step-1);
  }

  render () {
    const { step, fillCompleted = false, handleNextStep = null, activeNextStep} = this.props;
    const htmlSteps = [];

    for(let i = 1; i <= 3; i++){
      let klazz = '';
      let canBack = false;
      if (Number(step) > i){
        klazz = 'active can_back';
        canBack = true;
      }
      else if(Number(step) == i)
        klazz = fillCompleted ? 'active' : 'staying';

      htmlSteps.push(<div key={i} className={`item step-${i} ${klazz}`} onClick={()=>this.handleGoBack(i, step, canBack)}><span>{i}</span></div>)
    }

    return (
      <Fragment>
        <div className="bound-next-prev">
          <button className="ibtn keyboard back" onClick={this.handlePrevStep}>
            <i className="material-icons">arrow_right_alt</i>
          </button>
          <button className="ibtn keyboard next" disabled={!activeNextStep} onClick={handleNextStep}>
            <i className="material-icons">arrow_right_alt</i>
          </button>
        </div>
        <div className="bound-step-status d-flex justify-content-between align-items-center">
          <span className={`order-category upper ${this.category['slug']}`} onClick={() => this.goBack(0)}>{this.category['name']}</span>
          <div className="step-status">
            { htmlSteps }
          </div>
        </div>
      </Fragment>
    )
  }
}
export default StepStatus;
