import React, {Fragment, PureComponent} from "react";
import {DATE_FORMAT} from '../helpers/AppConstant';
import InputAutoComplete from '../common/InputAutoComplete';
import InputDatePicker from '../common/InputDatePicker';
import SelectPicker from '../common/SelectPicker';
import DatePicker from '../common/DatePicker';
import moment from 'moment';
import WrapComponent from './WrapComponent';
import Select from 'react-select';
import {_GET} from '../helpers/HttpRequest';
import { isEmpty } from 'lodash';

class StepOne extends PureComponent {

  constructor(props) {
    super(props);
    const {data} = props;
    this.state = {
      pickupAddress: data.address,
      pickupDate: data.preferred_pickup_date,
      frequency: data.frequency,
      fundraiserId: data.fundraiser_id,
      availabelDates: data.availabel_dates
    };
    this.prevPostalCode = null;
  }

  getAvailabelDates = (postalCode) => {
    if (isEmpty(postalCode)) return;

    _GET(`/api/v1/service_areas?postal_code=${postalCode}`, {}, (result, error_code) => {
      if (error_code == 0)
        this.setState({availabelDates: result})
      else
        this.setState({availabelDates: []})
    });
  }

  clickOnContinue = () => {
    const { handleStep } = this.props;
    handleStep(2, this.state)
  }

  handleChangeAddress = (pickupAddress, location, postalCode = "") => {
    this.getAvailabelDates(postalCode);
    let newState = {pickupAddress: pickupAddress};
    if (this.prevPostalCode != postalCode){
      newState['pickupDate'] = '';
      newState['availabelDates'] = [];
    }
    this.setState(newState)
    this.prevPostalCode = postalCode;
  }

  handleFundraiserChange = fundraiserId => {
    this.setState({ fundraiserId });
  }

  handlePickupDateRadioChange = (event) => {
    this.setState({pickupDate: event.target.value})
  }

  checkFillCompleted = () => {
    const { pickupAddress, frequency, pickupDate } = this.state;
     // || frequency === ''
    return !(pickupAddress === '' || pickupDate === '');
  }

  renderRadioDate = (data) => {
    const { pickupDate } = this.state;
    let checked = data[0] == pickupDate
    return(
      <div className="custom-control custom-radio mb-1"  key={`date-${data[0]}`} >
        <input checked={checked} onChange={this.handlePickupDateRadioChange} id={`radio_available_${data[0]}`}
          type="radio" name="pickup_date" value={data[0]} className="custom-control-input" />
        <label htmlFor={`radio_available_${data[0]}`} className="custom-control-label">{data[1]}</label>
      </div>
    )
  }

  renderNoSelectPickDate = () => {
    const { pickupAddress } = this.state;
    if (!isEmpty(this.prevPostalCode))
      return (<div className="font-italic">No pick date match with pickup address</div>)
    else if (!isEmpty(pickupAddress))
      return (<div className="font-italic">Can not get postal code</div>)
    else
      return ""
  }
  renderSelectAvailableDates = (availabelDates) => {
    let length = availabelDates.length;
    return(
      <Fragment>
        <div className="input-field" style={{marginBottom: "10px"}}>
          <label>Select Available Pick Date</label>
        </div>
        {
          length > 0 &&
          availabelDates.map(date => {
            return this.renderRadioDate(date)
          })
        }
        {
          length == 0 && this.renderNoSelectPickDate()
        }
      </Fragment>
    )
  }

  render () {
    const { pickupAddress, frequency, pickupDate, fundraiserId, availabelDates } = this.state;
    const { frequencyCollection, fundraiserCollection, data } = this.props;
    const fillCompleted = this.checkFillCompleted();
    const activeContinue = fillCompleted ? 'active' : 'disabled';
    return (
      <WrapComponent className="wrap-step-one" step='1'
        fillCompleted={fillCompleted}
        handleNextStep={this.clickOnContinue}>
        {
          data['category'] == 'charity' &&
          <div className="input-field">
            <label>Fundraiser</label>
            <SelectPicker
              name="fundraiser_id"
              placeholder="Select"
              includeBlank={false}
              value={fundraiserId}
              onChange={this.handleFundraiserChange}
              options={fundraiserCollection} />
          </div>
        }


        <InputAutoComplete
          validateBC={true}
          name="pickup_address"
          value={pickupAddress}
          onChange={this.handleChangeAddress}
          placeholder='123 Apple Avenue'
          label="Pickup Address"/>

      {/*  <InputDatePicker
          name="pickup_date"
          onChange={this.handlePickupDateChange}
          label="Requested Pickup Date"
          daysInAdv={2}
          value={pickupDate}
        />*/}

        {
          this.renderSelectAvailableDates(availabelDates)
        }

        {/*<div className="input-field">
          <label>Preferred Frequency</label>
          <SelectPicker
            name="frequency"
            placeholder="How often?"
            includeBlank={false}
            value={frequency}
            onChange={this.handleFrequencyChange}
            options={frequencyCollection} />
        </div>*/}


        <button className={`btn btn-continue step-1 ${activeContinue}`} type="button" onClick={this.clickOnContinue}>
          Continue
        </button>
      </WrapComponent>
    )
  }
}
export default StepOne;
