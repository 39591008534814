import React, {Fragment, PureComponent} from "react";
import Util from './Util';

class InputDatePicker extends PureComponent {

  constructor(props) {
    super(props);
    this.datePicker = null;
    this.fileId = `field_${this.props.name}`
  }

  componentDidMount = () => {
    this.datePicker = (new DateTimePicker()).init(document.getElementById(this.fileId), 'jr-date');
    let self = this;
    this.datePicker.on('dp.change', function(ev){
      self.props.onChange(ev.currentTarget.value)
    })
  }

  // handleChange = (event) => {
  //   this.props.onChange(event.target.value);
  // }

  render () {
    const { value = null, name, label, daysInAdv = null,
           placeholder = 'DD/MM/YYYY', type = 'text' } = this.props;

    return (
      <div className="input-field icon-end">
        <label htmlFor={this.fileId}>{label}</label>
        <div className="bound-input">
          <input
            placeholder={`${placeholder}`}
            type={`${type}`} name={`${name}`}
            data-days-in-adv={daysInAdv}
            defaultValue={value}
            id={this.fileId}
          />
        </div>
      </div>
    )
  }
}
export default InputDatePicker;
