import React, { PureComponent} from "react";
import WrapComponent from './WrapComponent';

class StepDone extends PureComponent {

  render () {

    return (
      <WrapComponent className="wrap-step-done">
        <div className="thank-submit">
          Thank you for your submitting your request.
          <br />
          Please check your email for date confirmation.
        </div>
      </WrapComponent>
    )
  }
}
export default StepDone;
