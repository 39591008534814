import React, {Fragment, PureComponent} from "react";
import Util from './Util';

class InputAutoComplete extends PureComponent {

  constructor(props) {
    super(props);
    this.geocoder = new google.maps.Geocoder();
  }

  componentDidMount = () => {
    const { name, geocoder = false, validateAreas = true} = this.props;
    const inputElement = document.getElementById(this.fileId())
    const address = AutoPlaceComplete.init(inputElement, false);
    address.addListener('place_changed', () => {
      const place = address.getPlace();
      if (!place.geometry){
        geocoder ? this.getGeometryFromAddress(place.name) : this.props.onChange(place.name)
      }
      else{
        if (validateAreas && AutoPlaceComplete.validateAddressAreas(place) == false)
          this.props.onChange("")
        else
          this.props.onChange(place.formatted_address, place.geometry.location, this.getPostalCode(place.address_components));
      }
    });
  }

  getGeometryFromAddress = (address) => {
    this.geocoder.geocode( { 'address': address}, (results, status) => {
      if (status == 'OK'){
        this.props.onChange(address, results[0].geometry.location, this.getPostalCode(results[0].address_components))
      }
      else
        this.props.onChange(address);
    });
  }

  handleChange = (event) => {
    this.props.onChange(event.target.value);
  }

  fileId = () => {
    const { name } = this.props;
    return `field_${name}`;
  }

  getPostalCode = (address) =>{
    let length = address.length;
    for(let i = length-1; i > 0; i--){
      let types = address[i].types;
      for(let j = 0; j < types.length; j++){
        if (types[j] == "postal_code")
          return address[i].short_name
      }
    }
    return null;
  }

  render () {
    const { value='', name = Util.randomString(),
            label, placeholder = '', type = 'text',
            onChange, disabled = false } = this.props;

    return (
      <div className="input-field" id="pac-container">
        <label htmlFor={`field_${name}`}>{label}</label>
        <input
          value={value}
          placeholder={`${placeholder}`}
          type={`${type}`} name={`${name}`}
          id={this.fileId()}
          onChange={this.handleChange}
          disabled={disabled}
        />
      </div>
    )
  }
}
export default InputAutoComplete;
