import React, { PureComponent } from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

const FORMAT = 'dd/MM/yyyy';

class DatePicker extends PureComponent {
  render () {
    const {value, onDayChange} = this.props;
    return (
      <DayPickerInput
        formatDate={formatDate}
        format={FORMAT}
        parseDate={parseDate}
        placeholder={`${FORMAT}`}
        onDayChange={onDayChange}
      />
    );
  }
}
export default DatePicker;