import jquery from 'jquery';
import moment from 'moment/dist/moment';
const geolib = require('geolib');

window.geolib = geolib;
window.$ = window.jQuery = jquery;
window.moment = moment;

require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()
require('bootstrap');
require('bootstrap-select')
require("jquery_nested_form")
require('bootstrap-daterangepicker/daterangepicker')


